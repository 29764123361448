import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import router from './router/index'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store/index'
const HelloJs = require('hellojs/dist/hello.all.min.js');
const VueHello = require('vue-hellojs');

HelloJs.init({
  google: process.env.VUE_APP_GOOGLE_APP_CLIENT_ID
}, {});
Vue.use(VueHello, HelloJs);

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueRouter)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
