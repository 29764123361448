import Vue from 'vue'
import Vuex from 'vuex'
import Toasted from 'vue-toasted';
import app from './modules/app'
import auth from './modules/auth'
import incomes from './modules/incomes'
import expenses from './modules/expenses'
import payments from './modules/payments'
import credit from './modules/credit'
import charts from './modules/charts'
import accounts from './modules/accounts'
import transactions from './modules/transactions'
import router from '../router'

import * as getters from './getters'

Vue.use(Vuex)
Vue.use(Toasted);

const store = new Vuex.Store({
  strict: true, // process.env.NODE_ENV !== 'production',
  getters,
  modules: {
    app,
    auth,
    incomes,
    expenses,
    payments,
    credit,
    charts,
    accounts,
    transactions,
  },
  state: {},
  mutations: {},
  actions: {
    processError(state, error) {
      if (typeof(error.response.data) !== 'undefined') {
        if (error.response.data.data === 'Provided token is expired.' || error.response.data.data === 'An error while decoding token: Wrong number of segments') {
          sessionStorage.clear();
          if (router.currentRoute.name !== 'dashboard') {
            router.push({name: 'dashboard'});
          } else {
            router.go();
          }
        }
      }
    },

    displayError(state, msg) {
      Vue.toasted.error(msg, {
        position: 'bottom-center',
        iconPack: 'fontawesome',
        icon: 'fa-exclamation-circle',
        duration: 2500
      });
    },

    displayWarning(state, msg) {
      Vue.toasted.info(msg, {
        position: 'bottom-center',
        iconPack: 'fontawesome',
        icon: 'fa-info-circle',
        duration: 2500
      });
    },

    displaySuccess(state, msg) {
      Vue.toasted.success(msg, {
        position: 'bottom-center',
        iconPack: 'fontawesome',
        icon: 'fa-check-circle',
        duration: 2500
      });
    }
  }
})

Vue.use(store);

export default store
