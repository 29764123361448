import axios from 'axios'

const state = {
  url: process.env.VUE_APP_ROOT_API + '/credit',
  loading: false,
  credit: {
    status: '',
    data: []
  },
  periodsOptions: []
}

const mutations = {
  updateCreditLoading(state, payload) {
    state.loading = payload;
  },

  updateCredit(state, payload) {
    state.credit = payload;
  },

  updateCreditPeriods(state, payload) {
    state.periodsOptions = payload.data;
  }
}

const actions = {
  fetchCredit({commit, state, dispatch}, period) {
    const params = (typeof period != 'undefined') ? ('?period=' + period) : '';
    this.commit('updateCreditLoading', true);
      axios.get(
          state.url + params,
          { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } }
      ).then((response) => {
          commit('updateCredit', response.data);
      }).catch(error => {
          dispatch('processError', error);
      }).then(()=> {
          commit('updateCreditLoading', false);
      });
  },

  fetchCreditPeriods({commit, state, dispatch}) {
    this.commit('updateCreditLoading', true);

      return new Promise((resolve) => {
          axios.get(
              state.url + '/periods',
              {
                  headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}
              }
          ).then((response) => {
              commit('updateCreditPeriods', response.data);
          }).catch(error => {
              dispatch('processError', error);
          }).then(() => {
              commit('updateCreditLoading', false);
              resolve('done');
          })
      });
  },

  addCredit({commit, dispatch}, payload) {
    commit('updateCreditLoading', true);

    axios.post(
        state.url,
        {
            description: payload.description,
            type: payload.type,
            term: payload.term,
            balance: payload.balance,
            color: payload.color
        },
        { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } })
        .then(response => {
            dispatch(
                response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                response.data.data
            );
        })
        .catch(error => {
            dispatch('processError', error);
        })
        .then(()=> {
          commit('updateCreditLoading', false);
        })
        .then( () => {
          dispatch('fetchCredit')
              .then(() => dispatch('fetchCredit'))
              .then(() => dispatch('fetchCreditPeriods'))
        });
  },

  updateCredit({commit, dispatch}, payload) {
    commit('updateCreditLoading', true);

    axios.put(
        state.url + '/' + payload.id,
        {
            description: payload.description,
            type: payload.type,
            term: payload.term,
            balance: payload.balance,
            color: payload.color
        },
        { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } })
        .then(response => {
            dispatch(
                response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                response.data.data
            );
        })
        .catch(error => {
            dispatch('processError', error);
        })
        .then(()=> {
          commit('updateCreditLoading', false);
        })
        .then( () => {
          dispatch('fetchCredit')
              .then(() => dispatch('fetchCreditPeriods'))
        });
  },

  deleteCredit({commit, dispatch}, payload) {
    commit('updateCreditLoading', true);

    axios.delete(state.url + '/' + payload,
        { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } })
        .then(response => {
            dispatch(
                response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                response.data.data
            );
        })
        .catch(error => {
            dispatch('processError', error);
        })
        .then(()=> {
          commit('updateCreditLoading', false);
        })
        .then( () => {
          dispatch('fetchCredit')
              .then(() => dispatch('fetchCreditPeriods'))
        });
  },

    unDeleteCredit({commit, dispatch}, payload) {
        commit('updateCreditLoading', true);

        axios.get(state.url + '/undelete/' + payload,
            { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } })
            .then(response => {
                dispatch(
                    response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                    response.data.data
                );
            })
            .catch(error => {
                dispatch('processError', error);
            })
            .then(()=> {
                commit('updateCreditLoading', false);
            })
            .then( () => {
                dispatch('fetchCredit')
                    .then(() => dispatch('fetchCreditPeriods'))
            });
    },

    updateBalance({commit, dispatch}, payload) {
        commit('updateCreditLoading', true);

        axios.post(
            state.url + '/' + payload.id + '/balance',
            {
                id: payload.id,
                amount: payload.amount,
                date: payload.date,
                type: payload.type
            },
            { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } })
            .then(response => {
                dispatch(
                    response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                    response.data.data
                );
            })
            .catch(error => {
                dispatch('processError', error);
            })
            .then(()=> {
                commit('updateCreditLoading', false);
            })
            .then( () => {
                dispatch('fetchCredit')
                    .then(() => dispatch('fetchCreditPeriods'))
            });
    },

    uploadStatement({dispatch}, id, file, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);

        axios.post(
            state.url + '/' + id + '/transactions',
            formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': 'bearer ' + sessionStorage.getItem('token')
                },
                onUploadProgress
            }).then(response => {
            dispatch(
                response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                response.data.data
            );
        })
            .catch(error => {
                dispatch('processError', error);
            });
    }
}

export default {
  state,
  mutations,
  actions,
}
