import axios from 'axios'

const state = {
    url: process.env.VUE_APP_ROOT_API + '/charts',
    loading: false,
    summary: {
        status: '',
        data: []
    },
    history: {
        status: '',
        labels: [],
        data: []
    },
    expenses: {
        status: '',
        labels: [],
        data: []
    },
    expenseBySub: {
        status: '',
        data: []
    }
}

const mutations = {
    updateChartLoading(state, payload) {
        state.loading = payload;
    },

    updateSummaryChart(state, payload) {
        state.summary = payload;
    },

    updateCreditHistoryChart(state, payload) {
        state.history = payload;
    },

    updateExpensesChart(state, payload) {
        state.expenses = payload;
    },

    updateExpensesBySub(state, payload) {
        state.expenseBySub = payload;
    },
}

const actions = {
    fetchSummary({commit, state, dispatch}) {
        this.commit('updateChartLoading', true);

        return new Promise((resolve) => {
            axios.get(
                state.url + '/summary',
                {
                    headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}
                }
            ).then((response) => {
                commit('updateSummaryChart', response.data);
            }).catch(error => {
                dispatch('processError', error);
            }).then(() => {
                commit('updateChartLoading', false);
                resolve('done');
            })
        });
    },

    fetchCreditHistory({commit, state, dispatch}) {
        this.commit('updateChartLoading', true);

        return new Promise((resolve) => {
            axios.get(
                state.url + '/history',
                {
                    headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}
                }
            ).then((response) => {
                commit('updateCreditHistoryChart', response.data);
            }).catch(error => {
                dispatch('processError', error);
            }).then(() => {
                commit('updateChartLoading', false);
                resolve('done');
            })
        });
    },

    fetchExpensesByCategories({commit, state, dispatch}) {
        this.commit('updateChartLoading', true);

        return new Promise((resolve) => {
            axios.get(
                state.url + '/expenses',
                {
                    headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}
                }
            ).then((response) => {
                commit('updateExpensesChart', response.data);
            }).catch(error => {
                dispatch('processError', error);
            }).then(() => {
                commit('updateChartLoading', false);
                resolve('done');
            })
        });
    },

    fetchExpensesBySubCategories({commit, state, dispatch}) {
        this.commit('updateChartLoading', true);

        return new Promise((resolve) => {
            axios.get(
                state.url + '/categorized-expenses',
                {
                    headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}
                }
            ).then((response) => {
                commit('updateExpensesBySub', response.data);
            }).catch(error => {
                dispatch('processError', error);
            }).then(() => {
                commit('updateChartLoading', false);
                resolve('done');
            })
        });
    },
}

export default {
    state,
    mutations,
    actions,
}
