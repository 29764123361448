<template>
  <v-app id="inspire">
    <v-app-bar app>
      <v-toolbar-title>Turmel Family Budget</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "AuthLayout"
}
</script>

<style scoped>

</style>