import axios from 'axios'

const state = {
    url: process.env.VUE_APP_ROOT_API + '/accounts',
    loading: false,
    accounts: []
}

const mutations = {
    updateAccountLoading(state, payload) {
        state.loading = payload;
    },

    updateAccounts(state, payload) {
        state.accounts = payload.data;
    }
}

const actions = {
    fetchAccounts({commit, state, dispatch}) {
        this.commit('updateAccountLoading', true);
        axios.get(
            state.url,
            { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } }
        ).then((response) => {
            commit('updateAccounts', response.data);
        }).catch(error => {
            dispatch('processError', error);
        }).then(()=> {
            commit('updateAccountLoading', false);
        });
    },

    addAccount({commit, dispatch}, payload) {
        commit('updateAccountLoading', true);

        axios.post(
            state.url,
            {
                title: payload.title,
                description: payload.description,
                balance: payload.balance
            },
            { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } })
            .then(response => this.responseData = response.data)
            .catch(error => {
                dispatch('processError', error);
            })
            .then(()=> {
                commit('updateAccountLoading', false);
            })
            .then( () => {
                dispatch('fetchAccounts')
            });
    },

    updateAccount({commit, dispatch}, payload) {
        commit('updateAccountLoading', true);

        axios.put(
            state.url + '/' + payload.id,
            {
                title: payload.title,
                description: payload.description,
                balance: payload.balance
            },
            { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } })
            .then(response => this.responseData = response.data)
            .catch(error => {
                dispatch('processError', error);
            })
            .then(()=> {
                commit('updateAccountLoading', false);
            })
            .then( () => {
                dispatch('fetchAccounts')
            });
    },

    deleteAccount({commit, dispatch}, payload) {
        commit('updateExpensesLoading', true);

        axios.delete(state.url + '/' + payload,
            {headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}}
        )
            .then(response => {
                dispatch(
                    response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                    response.data.data
                );
            })
            .catch(error => {
                dispatch('processError', error);
            })
            .then(() => {
                commit('updateAccountLoading', false);
            })
            .then(() => {
                dispatch('fetchAccounts')
            });
    },

    transferAmount({commit, dispatch}, payload) {
        commit('updateExpensesLoading', true);

        axios.post(
            state.url + '/transfer',
            {
                source_id: payload.source_id,
                dest_id: payload.dest_id,
                amount: payload.amount
            },
            { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } })
            .then(response => this.responseData = response.data)
            .catch(error => {
                dispatch('processError', error);
            })
            .then(()=> {
                commit('updateAccountLoading', false);
            })
            .then( () => {
                dispatch('fetchAccounts')
            });
    },
}


export default {
  state,
  mutations,
  actions,
}
