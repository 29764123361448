import axios from 'axios'

const state = {
  url: process.env.VUE_APP_ROOT_API + '/incomes',
  loading: true,
  incomes: {
    status: '',
    data: [],
    total: ''
  },
  periodsOptions: []
}

const mutations = {
  updateIncomesLoading(state, payload) {
    state.loading = payload;
  },

  updateIncomes(state, payload) {
    state.incomes = payload;
  },

  updatePeriods(state, payload) {
    state.periodsOptions = payload.data;
  }
}

const actions = {
  fetchIncomes({state, commit, dispatch}) {
    this.commit('updateIncomesLoading', true);
    axios.get(
        state.url,
        {
          headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') }
        }
    ).then((response) => {
      commit('updateIncomes', response.data);
    }).catch(error => {
        dispatch('processError', error);
    }).then(()=> {
      commit('updateIncomesLoading', false);
    });
  },

  fetchIncomesPeriods({state, commit}) {
    commit('updateIncomesLoading', true);

    return new Promise((resolve) => {
      axios.get(
          state.url + '/periods',
          {
              headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}
          }
      ).then((response) => {
          this.commit('updatePeriods', response.data);
      }).catch(error => {
          this.commit('updatePeriods', error);
      }).then(() => {
          this.commit('updateIncomesLoading', false);
          resolve('done');
      });
    });
  },

  addIncome({commit, dispatch}, payload) {
    commit('updateIncomesLoading', true);

    axios.post(
        state.url,
        {
          description: payload.description,
          amount: payload.amount,
          date: payload.date,
          account_id: payload.account
        }, { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } }
        )
        .then(response => {
            dispatch(
                response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                response.data.data
            );
        })
        .catch(error => { dispatch('processError', error); })
        .then(()=> { commit('updateIncomesLoading', false); })
        .then( () => {
            dispatch('fetchIncomes').then(() => dispatch('fetchIncomesPeriods'))
        });
  },

  updateIncome({commit, dispatch}, payload) {
    commit('updateIncomesLoading', true);

    axios.put(
        state.url + '/' + payload.id,
        {
            description: payload.description,
            amount: payload.amount,
            date: payload.date,
            account_id: payload.account
        },{ headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') }} )
        .then(response => {
            dispatch(
                response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                response.data.data
            );
        })
        .catch(error => { dispatch('processError', error); })
        .then(()=> { commit('updateIncomesLoading', false); })
        .then( () => {
            dispatch('fetchIncomes').then(() => dispatch('fetchIncomesPeriods'))
        });
  },

  deleteIncome({commit, dispatch}, payload) {
    commit('updateIncomesLoading', true);

    axios.delete(
        state.url + '/' + payload,
        { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } }
    )
        .then(response => {
            dispatch(
                response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                response.data.data
            );
        })
        .catch(error => { dispatch('processError', error); })
        .then(()=> { commit('updateIncomesLoading', false); })
        .then( () => {
            dispatch('fetchIncomes').then(() => dispatch('fetchIncomesPeriods'))
        });
    }
}


export default {
  state,
  mutations,
  actions,
}
