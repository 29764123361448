import axios from 'axios'

const state = {
    url: process.env.VUE_APP_ROOT_API + '/expenses',
    loading: true,
    expenses: {
        status: '',
        data: [],
        total: ''
    },
    periodsOptions: [],
    categories: []
}

const mutations = {
  updateExpensesLoading(state, payload) {
    state.loading = payload;
  },

  updateExpenses(state, payload) {
    state.expenses = payload;
  },

  updateExpensePeriods(state, payload) {
    state.periodsOptions = payload.data;
  },

    updateExpenseCategories(state, payload) {
        state.categories = payload.data;
    }
}

const actions = {
    fetchExpenses({commit, state, dispatch}) {
        this.commit('updateExpensesLoading', true);

        axios.get(
            state.url,
            {headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}}
        ).then((response) => {
            commit('updateExpenses', response.data);
        }).catch(error => {
            dispatch('processError', error);
        }).then(() => {
            commit('updateExpensesLoading', false);
        });
    },

    fetchExpensesCategories({commit}) {
        commit('updateExpensesLoading', true);

        return new Promise((resolve, reject) => {
            axios.get(
                process.env.VUE_APP_ROOT_API + '/categories',
                {
                    headers: {
                        'Authorization': 'bearer ' + sessionStorage.getItem('token')
                    }
                })
                .then((response) => {
                    commit('updateExpenseCategories', response.data);
                })
                .catch(error => {
                    reject(error);
                })
                .then(() => {
                    commit('updateExpensesLoading', false);
                    resolve('done');
                });
        });
    },

    fetchExpensesPeriods({commit, state, dispatch}) {
        commit('updateExpensesLoading', true);

        return new Promise((resolve) => {
            axios.get(
                state.url + '/periods',
                {
                    headers: {
                        'Authorization': 'bearer ' + sessionStorage.getItem('token')
                    }
                })
                .then((response) => {
                    commit('updateExpensePeriods', response.data);
                })
                .catch(error => {
                    dispatch('processError', error);
                })
                .then(() => {
                    commit('updateExpensesLoading', false);
                    resolve('done');
                });
        });
    },

    addExpense({commit, dispatch}, payload) {
        commit('updateExpensesLoading', true);

        axios.post(
            state.url,
            {
                description: payload.description,
                amount: payload.amount,
                minimum: payload.minimum,
                due_date: payload.due_date,
                type: payload.type,
                account_id: payload.account,
                category_id: payload.category
            },
            {headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}}
        )
            .then(response => {
                dispatch(
                    response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                    response.data.data
                );
            })
            .catch(error => {
                dispatch('processError', error);
            })
            .then(() => {
                commit('updateExpensesLoading', false);
            })
            .then(() => {
                dispatch('fetchExpenses').then(() => dispatch('fetchExpensesPeriods'))
            });
    },

    updateExpense({commit, dispatch}, payload) {
        commit('updateExpensesLoading', true);

        axios.put(
            state.url + '/' + payload.id,
            {
                description: payload.description,
                amount: payload.amount,
                minimum: payload.minimum,
                due_date: payload.due_date,
                type: payload.type,
                account_id: payload.account,
                category_id: payload.category
            },
            {headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}}
        )
            .then(response => {
                dispatch(
                    response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                    response.data.data
                );
            })
            .catch(error => {
                dispatch('processError', error);
            })
            .then(() => {
                commit('updateExpensesLoading', false);
            })
            .then(() => {
                dispatch('fetchExpenses').then(() => dispatch('fetchExpensesPeriods'))
            });
    },

    deleteExpense({commit, dispatch}, payload) {
        commit('updateExpensesLoading', true);

        axios.delete(state.url + '/' + payload,
            {headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}}
        )
            .then(response => {
                dispatch(
                    response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                    response.data.data
                );
            })
            .catch(error => {
                dispatch('processError', error);
            })
            .then(() => {
                commit('updateExpensesLoading', false);
            })
            .then(() => {
                dispatch('fetchExpenses').then(() => dispatch('fetchExpensesPeriods'))
            });
    },

    batchDeleteExpenses({commit, dispatch}, payload) {
        commit('updateExpensesLoading', true);

        axios.post(
            state.url + '/batch-delete',
            {
                ids: payload
            },
            {headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}}
        ).then(response => {
            dispatch(
                response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                response.data.data
            );
        })
            .catch(error => {
                dispatch('processError', error);
            })
            .then(() => {
                commit('updateExpensesLoading', false);
            })
            .then(() => {
                dispatch('fetchExpenses')
                    .then(() => dispatch('fetchExpensesPeriods'))
            });
    },

    carryExpenses({commit, dispatch}, payload) {
        commit('updateExpensesLoading', true);

        axios.post(
            state.url + '/carry',
            {
                ids: payload
            },
            {headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}}
        ).then(response => {
            dispatch(
                response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                response.data.data
            );
        })
            .catch(error => {
                dispatch('processError', error);
            })
            .then(() => {
                commit('updateExpensesLoading', false);
            })
            .then(() => {
                dispatch('fetchExpenses')
                    .then(() => dispatch('fetchExpensesPeriods'))
            });
    }
}

export default {
  state,
  mutations,
  actions,
}
