import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components/auth/AuthLayout'
import AppLayout from '../components/AppLayout'
// import store from '../store'

Vue.use(Router)

/*
const EmptyParentComponent = {
  template: '<router-view></router-view>',
}
*/

// let entryUrl = null;

let router = new Router({
  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes: [
    {
      path: '*',
      redirect: { name: 'dashboard' },
    },
    {
      path: '/auth',
      component: AuthLayout,
      children: [
        {
          name: 'login',
          path: 'login',
          component: () => import('../components/auth/Login.vue'),
        },
        {
          path: '',
          redirect: { name: 'login' },
        },
      ],
    },
    {
      path: '/',
      component: AppLayout,
      children: [
        {
          name: 'dashboard',
          path: '',
          component: () => import('../components/dashboard/Dashboard.vue'),
          default: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          name: 'incomes',
          path: 'incomes',
          component: () => import('../components/incomes/Incomes.vue'),
          default: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          name: 'expenses',
          path: 'expenses',
          component: () => import('../components/expenses/Expenses.vue'),
          default: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          name: 'payments',
          path: 'payments',
          component: () => import('../components/payments/Payments.vue'),
          default: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          name: 'credit',
          path: 'credit',
          component: () => import('../components/credit/Credit.vue'),
          default: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          name: 'trransactions',
          path: 'Transactions',
          component: () => import('../components/transactions/Transactions.vue'),
          default: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          name: 'accounts',
          path: 'accounts',
          component: () => import('../components/accounts/Accounts.vue'),
          default: true,
          meta: {
            requiresAuth: true
          },
        },
      ],
    },
  ],
});


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (sessionStorage.getItem('token') == null) {
      next({
        path: '/auth/login',
        params: {nextUrl: to.fullPath}
      })
    } else {
      next();
    }
  } else {
    next();
  }
})


export default router
