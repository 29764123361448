<template>
  <div>
    <v-list
        dense
        nav
    >
      <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.route"
          link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-switch
              ml="2"
              label="Dark theme"
              v-model="darkTheme"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>

</template>

<script>
export default {
  name: "Navigation",
  data () {
    return {
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', route: 'dashboard' },
        { title: 'Incomes', icon: 'mdi-cash-plus', route: 'incomes' },
        { title: 'Expenses', icon: 'mdi-cash-minus', route: 'expenses' },
        { title: 'Payments', icon: 'mdi-cash-check', route: 'payments' },
        { title: 'Credit', icon: 'mdi-credit-card', route: 'credit' },
        { title: 'Transactions', icon: 'mdi-invoice-list', route: 'transactions' },
        { title: 'Accounts', icon: 'mdi-bank', route: 'accounts' },
      ],
      right: null,
      darkTheme: true,
    }
  },

  watch: {
    darkTheme(val) {
      this.$vuetify.theme.dark = val;
    }
  }
}
</script>

<style scoped>

</style>