import axios from 'axios'

const state = {
  url: process.env.VUE_APP_ROOT_API + '/transactions',
  loading: false,
  transactions: {
    status: '',
    data: []
  },
  periodsOptions: [],
  creditOptions: [],
}

const mutations = {
  updateTransactionsLoading(state, payload) {
    state.loading = payload;
  },

  updateTransactions(state, payload) {
    state.transactions = payload;
  },

  updateTransactionPeriods(state, payload) {
      state.periodsOptions = payload.data;
  },
}

const actions = {
  fetchTransactions({commit, dispatch}) {
    this.commit('updateTransactionsLoading', true);

      axios.get(
          state.url,
          { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } }
      ).then((response) => {
          commit('updateTransactions', response.data);
      }).catch(error => {
          dispatch('processError', error);
      }).then(()=> {
          commit('updateTransactionsLoading', false);
      });
  },

  fetchTransactionsPeriods({commit, dispatch}) {
    this.commit('updateTransactionsLoading', true);

      return new Promise((resolve) => {
          axios.get(
              state.url + '/periods',
              {
                  headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}
              }
          ).then((response) => {
              commit('updateTransactionPeriods', response.data);
          }).catch(error => {
              dispatch('processError', error);
          }).then(() => {
              commit('updateTransactionsLoading', false);
              resolve('done');
          });
      });
  },

  categorizeTransactions({commit, dispatch}, payload) {
      this.commit('updateTransactionsLoading', true);

      return new Promise((resolve) => {
          axios.post(
              state.url + '/categorize',
                {
                    category_id: payload.category_id,
                    ids: payload.ids
                },
              {
                  headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}
              }
          ).then(() => {
              dispatch("fetchTransactions");
          }).catch(error => {
              dispatch('processError', error);
          }).then(() => {
              commit('updateTransactionsLoading', false);
              resolve('done');
          });
      });
  }

}

export default {
  state,
  mutations,
  actions,
}
