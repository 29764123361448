import axios from 'axios'

const state = {
  url: process.env.VUE_APP_ROOT_API + '/payments',
  loading: false,
  payments: {
    status: '',
    data: []
  },
  periodsOptions: []
}

const mutations = {
  updatePaymentsLoading(state, payload) {
    state.loading = payload;
  },

  updatePayments(state, payload) {
    state.payments = payload;
  },

  updatePaymentPeriods(state, payload) {
    state.periodsOptions = payload.data;
  }
}

const actions = {
  fetchPayments({commit, dispatch}) {
    this.commit('updatePaymentsLoading', true);

      axios.get(
          state.url,
          { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } }
      ).then((response) => {
          commit('updatePayments', response.data);
      }).catch(error => {
          dispatch('processError', error);
      }).then(()=> {
          commit('updatePaymentsLoading', false);
      });
  },

  fetchPaymentsPeriods({commit, dispatch}) {
    this.commit('updatePaymentsLoading', true);

      return new Promise((resolve) => {
          axios.get(
              state.url + '/periods',
              {
                  headers: {'Authorization': 'bearer ' + sessionStorage.getItem('token')}
              }
          ).then((response) => {
              commit('updatePaymentPeriods', response.data);
          }).catch(error => {
              dispatch('processError', error);
          }).then(() => {
              commit('updatePaymentsLoading', false);
              resolve('done');
          });
      });
  },

  addPayment({commit, dispatch}, payload) {
    commit('updatePaymentsLoading', true);

    axios.post(
        state.url,
        {
            amount: payload.amount,
            payment_date: payload.payment_date,
            bill_id: payload.bill_id,
            account_id: payload.account_id
        },
        { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } })
        .then(response => {
            dispatch(
                response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                response.data.data
            );
        })
        .catch(error => {
            dispatch('processError', error);
        })
        .then(()=> {
          commit('updatePaymentsLoading', false);
        })
        .then( () => {
          dispatch('fetchPayments')
              .then(() => dispatch('fetchExpenses'))
              .then(() => dispatch('fetchPaymentsPeriods'))
        });
  },

  updatePayment({commit, dispatch}, payload) {
    commit('updatePaymentsLoading', true);

    axios.put(
        state.url + '/' + payload.id,
        {
            amount: payload.amount,
            payment_date: payload.payment_date,
            bill_id: payload.bill_id,
            account_id: payload.account_id
        },
        { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } })
        .then(response => {
            dispatch(
                response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                response.data.data
            );
        })
        .catch(error => {
            dispatch('processError', error);
        })
        .then(()=> {
          commit('updatePaymentsLoading', false);
        })
        .then( () => {
          dispatch('fetchPayments')
              .then(() => dispatch('fetchPaymentsPeriods'))
        });
  },

  deletePayment({commit, dispatch}, payload) {
    commit('updatePaymentsLoading', true);

    axios.delete(state.url + '/' + payload,
        { headers: { 'Authorization': 'bearer ' + sessionStorage.getItem('token') } })
        .then(response => {
            dispatch(
                response.data.status === 'success' ? 'displaySuccess' : 'displayError',
                response.data.data
            );
        })
        .catch(error => {
            dispatch('processError', error);
        })
        .then(()=> {
          commit('updatePaymentsLoading', false);
        })
        .then( () => {
          dispatch('fetchPayments')
              .then(() => dispatch('fetchPaymentsPeriods'))
        });
  }
}

export default {
  state,
  mutations,
  actions,
}
