import axios from 'axios'

const state = {
    url: process.env.VUE_APP_ROOT_API + '/auth',
    loading: false,
    loginError: ''
}

const mutations = {
    updateLoginLoading(state, payload) {
        state.loading = payload;
    },

    raiseError(state, payload) {
        state.loginError = payload
    }
}

const actions = {
  googleAuth({state, commit}, payload) {
    commit('updateLoginLoading', true);
    return new Promise((resolve, reject) => {
      axios.post(state.url + '/google-auth', payload)
        .then(response => {
          if (response.data.status == 'success') {
            sessionStorage.setItem('token', response.data.token);
            sessionStorage.setItem('fullName', response.data.fullname);
            resolve(response.data);
          } else {

            reject(response.data);
          }
          commit('updateLoginLoading', false);
        }).catch(error => {
        commit('updateLoginLoading', false);
        reject(error);
      });
    });
  },

  logout() {
    return new Promise((resolve) => {
      sessionStorage.clear();
      resolve('done');
    });
  }
}

export default {
  state,
  mutations,
  actions,
}
